import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["isMobile", "articles"]),
    detail() {
      const index = this.articles.findIndex(
        (article) => article.id === this.$route.query.id
      );
      if (index > -1) {
        return this.articles[index];
      }
      return null;
    }
  },
  mounted() {}
};
