<template>
  <div class="page" :style="{ backgroundImage: `url(${pageBgImg})` }">
    <div class="container">
      <div :class="['detailContainer']" ref="detailContainer">
        <DetailPure :detail="detail" :key="detail.title" />
      </div>
    </div>
    <div class="flex bottom-btns">
      <div
        v-if="preArticle"
        class="btn flex-1 flex flex-ct p-v-12 m-r-10"
        @click="
          gotoPage('/beijing-ancient-tree-map-detail?id=' + preArticle.id)
        "
      >
        {{ preArticle.title }}
      </div>
      <div
        v-if="nextArticle"
        class="btn flex-1 flex flex-ct p-v-12 m-l-10"
        @click="
          gotoPage('/beijing-ancient-tree-map-detail?id=' + nextArticle.id)
        "
      >
        {{ nextArticle.title }}
      </div>
    </div>
    <div class="flex flex-ct footer p-b-20">
      ·奥迪中国与北京绿化基金会联合呈现·<br />支持单位：
      国家林业草原古树健康与古树文化工程技术研究中心
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import myMixinDetail from "./detail.js";
import DetailPure from "@/components/detail/detailPure_address_m.vue";
export default {
  components: {
    DetailPure
  },
  mixins: [myMixinDetail],

  computed: {
    ...mapState(["specialAddress", "articles"]),
    indexInSpecialAddress() {
      return this.specialAddress.findIndex(
        (item) => item.articleId === this.$route.query.id
      );
    },
    preBtnId() {
      const index = this.indexInSpecialAddress;
      if (index > 0) {
        return this.specialAddress[index - 1].articleId;
      } else if (index === 0) {
        return this.specialAddress[this.specialAddress.length - 1].articleId;
      }
      return "";
    },
    nextBtnId() {
      const index = this.indexInSpecialAddress;
      if (index < this.specialAddress.length - 1) {
        return this.specialAddress[index + 1].articleId;
      } else if (index === this.specialAddress.length - 1) {
        return this.specialAddress[0].articleId;
      }
      return "";
    },
    preArticle() {
      const index = this.indexInSpecialAddress;
      if (index > 0) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[index - 1].articleId
        );
      } else if (index === 0) {
        return this.articles.find(
          (article) =>
            article.id ===
            this.specialAddress[this.specialAddress.length - 1].articleId
        );
      }
      return null;
    },
    nextArticle() {
      const index = this.indexInSpecialAddress;
      if (index < this.specialAddress.length - 1) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[index + 1].articleId
        );
      } else if (index === this.specialAddress.length - 1) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[0].articleId
        );
      }
      return null;
    }
  },
  data() {
    return {
      pageBgImg: require("@/images/bg-2.jpg")
    };
  },
  watch: {
    "$route.query.id": function (val) {
      if (val) {
        this.$refs.detailContainer.scrollTop = 0;
      }
    }
  },
  methods: {
    gotoPage(routePath) {
      if (routePath) {
        this.$router.replace(routePath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.container {
  margin: 126px 32px 0;
  border-radius: 12px;
  background: rgba($color: #000000, $alpha: 0.4);
  border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.top {
  flex-shrink: 0;
  .part {
    height: 10px;
  }
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    display: block;
    margin-left: 4px;
    &.active {
      background-color: #40c066;
    }
  }
}
.title {
  font-size: 26px;
  color: #ffffff;
  background-image: url("~@/images/page-top-yinhao1.png"),
    url("~@/images/page-top-yinhao2.png");
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  background-size: 7px auto, 7px auto;
}
.subtitle {
  font-size: 13px;
  color: #ffffff;
}

.detailContainer {
  flex-grow: 0;
  overflow-y: auto;
  padding-bottom: 10px;
  background: #fff;
  border-radius: 12px;
}
.more {
  height: 53px;
  font-size: 13px;
  color: #a3a3a3;
  background: #fff;
  .img {
    width: 35px;
  }
}
.bottom-btns {
  font-size: 10px;

  margin: 20px 32px 20px;
  .btn {
    background: #0000007a;
    border-radius: 20px;
    border: 1px solid #ffffff61;
  }
}
.footer {
  font-size: 10px;
}
</style>
