<template>
  <div
    class="beijingAncientTreeMap_page_pc flex-ct"
    :style="{ backgroundImage: `url(${pageBgImg})` }"
  >
    <div class="flex middle">
      <div class="thumbs flex-cl">
        <div v-for="tree in allTrees" :key="tree.id" class="item flex-1">
          <img class="img" :src="tree.topImg" />
          <div
            :class="[
              'flex flex-ct cover',
              {
                active: tree.id === $route.query.id
              }
            ]"
            @click="gotoPage('/beijing-ancient-tree-map-detail?id=' + tree.id)"
          >
            {{ tree.title }}
          </div>
        </div>
      </div>
      <div class="container">
        <div :class="['detailContainer']" ref="detailContainer">
          <DetailPure :detail="detail" :key="detail.title" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import myMixinDetail from "./detail.js";
import DetailPure from "@/components/detail/detailPure_m.vue";
export default {
  components: {
    DetailPure
  },
  mixins: [myMixinDetail],

  computed: {
    ...mapState(["specialAddress", "articles"]),
    allTrees() {
      return this.specialAddress.map((item) => {
        const index = this.articles.findIndex(
          (_item) => _item.id === item.articleId
        );
        return this.articles[index];
      });
    },
    indexInSpecialAddress() {
      return this.specialAddress.findIndex(
        (item) => item.articleId === this.$route.query.id
      );
    },
    preBtnId() {
      const index = this.indexInSpecialAddress;
      if (index > 0) {
        return this.specialAddress[index - 1].articleId;
      } else if (index === 0) {
        return this.specialAddress[this.specialAddress.length - 1].articleId;
      }
      return "";
    },
    nextBtnId() {
      const index = this.indexInSpecialAddress;
      if (index < this.specialAddress.length - 1) {
        return this.specialAddress[index + 1].articleId;
      } else if (index === this.specialAddress.length - 1) {
        return this.specialAddress[0].articleId;
      }
      return "";
    },
    preArticle() {
      const index = this.indexInSpecialAddress;
      if (index > 0) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[index - 1].articleId
        );
      } else if (index === 0) {
        return this.articles.find(
          (article) =>
            article.id ===
            this.specialAddress[this.specialAddress.length - 1].articleId
        );
      }
      return null;
    },
    nextArticle() {
      const index = this.indexInSpecialAddress;
      if (index < this.specialAddress.length - 1) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[index + 1].articleId
        );
      } else if (index === this.specialAddress.length - 1) {
        return this.articles.find(
          (article) => article.id === this.specialAddress[0].articleId
        );
      }
      return null;
    }
  },
  data() {
    return {
      pageBgImg: require("@/images/pc/北京古树游览推荐-PC三级页面｜内容详情.jpg")
    };
  },
  watch: {
    "$route.query.id": function (val) {
      if (val) {
        this.$refs.detailContainer.scrollTop = 0;
      }
    }
  },
  methods: {
    gotoPage(routePath) {
      if (routePath) {
        this.$router.replace(routePath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.beijingAncientTreeMap_page_pc {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  color: #fff;
  .middle {
    padding: 0 100px;
    flex-grow: 0;
    height: 90%;
  }
  .thumbs {
    .item {
      width: 340px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0px;
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 27px;
        cursor: pointer;
        background: rgba($color: #000000, $alpha: 0.4);

        &.active {
          background: rgba($color: #000000, $alpha: 0);
        }
      }
    }
  }

  .container {
    margin: 0 0 0 100px;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .top {
    flex-shrink: 0;
    .part {
      height: 10px;
    }
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: block;
      margin-left: 4px;
      &.active {
        background-color: #40c066;
      }
    }
  }
  .title {
    font-size: 26px;
    color: #ffffff;
    background-image: url("~@/images/page-top-yinhao1.png"),
      url("~@/images/page-top-yinhao2.png");
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 7px auto, 7px auto;
  }
  .subtitle {
    font-size: 13px;
    color: #ffffff;
  }

  .detailContainer {
    flex-grow: 0;
    overflow-y: auto;
    padding-bottom: 10px;
    background: #fff;
    border-radius: 12px;
  }
  .more {
    height: 53px;
    font-size: 13px;
    color: #a3a3a3;
    background: #fff;
    .img {
      width: 35px;
    }
  }
  .bottom-btns {
    font-size: 10px;

    margin: 20px 32px 20px;
    .btn {
      background: #0000007a;
      border-radius: 20px;
      border: 1px solid #ffffff61;
    }
  }
  .footer {
    font-size: 10px;
  }
}
</style>
